import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import { IManagementAreaDetail } from '../../models/IManagementAreaDetail';
import styles from './ManagementAreaDetail.module.css';
import settings from '../../config';
import { fetchWithAuthorisationHeader, postWithAuthorisationHeader } from '../../services/AuthenticationService';
import LinearProgress from '@mui/material/LinearProgress/LinearProgress';
import Grid from '@mui/material/Grid/Grid';
import Typography from '@mui/material/Typography/Typography';
import { Chip, Divider, IconButton } from '@mui/material';
import { AssetChart } from './AssetChart/AssetChart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ProgramList from './ProgramList/ProgramList/ProgramList';
import { IAssetSearchCreateResponse } from '../../models/IAssetSearchCreateResponse';
import InfoIcon from '@mui/icons-material/Info';
import { IManagementAreaSearchFilters } from '../../models/IManagementAreaSearchFilters';
import { isFilterAssetSensitive } from '../../services/FilterService';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { IAssetSearchFilters } from '../../models/IAssetSearchFilters';
import { IAssetSearchCreateRequest } from '../../models/IAssetSearchCreateRequest';
import { AssetList } from './AssetList/AssetList/AssetList';
import { ApprovalResults } from './Approvals/ApprovalResults/ApprovalResults';
import { IAsset } from '../../models/IAsset';
import { IApproval } from '../../models/IApproval';
import { ManagementAreaStatusDropdown } from './ManagementAreaStatusDropdown/ManagementAreaStatusDropdown';
import { IManagementAreaStatus } from '../../models/IManagementAreaStatus';

interface IManagementAreaDetailProps {
  managementAreaId: number;
  cycleId: string;
  selectedFilterValues: IManagementAreaSearchFilters | null;
  isApprovalRefreshRequired: boolean;
  canEditMaStatus: boolean;
  isSameManagementAreaReClicked: boolean;
  onAssetSelected: Function;
  onAssetSearchCreated: Function;
  onApprovalSelected: Function;
  onApprovalResultsUpdated: Function;
  onManagementAreaStatusUpdated: Function;
}

export function ManagementAreaDetail(props: IManagementAreaDetailProps) {
    const [managementAreaDetail, setManagementAreaDetail] = useState<IManagementAreaDetail | null>(null);
    const [assetSearch, setAssetSearch] = useState<IAssetSearchCreateResponse | null>(null);
    const [selectedAssetSearchFilters, setSelectedAssetSearchFilters] = useState<IAssetSearchFilters | null>(null);
    const [showSpanList, setShowSpanList] = useState(true);
    const [showApprovals, setShowApprovals] = useState(true);
    const [spanListSearchGuid, setSpanListSearchGuid] = useState('');
    const [showStatusDropdown, setShowStatusDropdown] = useState(false);
    const lastManagementAreaIdRef = useRef(0);

    const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none',
        },
    });

    async function getFilteredSpanList() {

        let zoomToExtent: boolean = false;

        let filters: IAssetSearchFilters = {
            programNameIds: props.selectedFilterValues?.programNameIds,
            programTypeIds: props.selectedFilterValues?.programTypeIds,
            programStatuses: props.selectedFilterValues?.programStatuses,
            assetExternalReferenceId: props.selectedFilterValues?.assetId ?? '',
            taskTypeIds: props.selectedFilterValues?.taskTypeIds,
            fireAreas: props.selectedFilterValues?.fireAreas,
            approvalStatuses: props.selectedFilterValues?.approvalStatuses,
            assetStatuses: props.selectedFilterValues?.assetStatuses,
            companyIds: props.selectedFilterValues?.companyIds,
            newSpan: props.selectedFilterValues?.newSpan,
        };

        setSelectedAssetSearchFilters(filters);

        try {
            const createSearchRequest: IAssetSearchCreateRequest = {
                cycleId: props.cycleId,
                managementAreaId: props.managementAreaId,
                filters: filters
            };

            let response = await postWithAuthorisationHeader(settings.CMPLY_API_URL + 'assetSearch/create', createSearchRequest);
            if (response.status === 200) {
                let assetSearch = response.data as IAssetSearchCreateResponse;
                setSpanListSearchGuid(assetSearch.searchId);
                setAssetSearch(assetSearch);
                zoomToExtent = true;
                props.onAssetSearchCreated(assetSearch, zoomToExtent);
            }
            else {
                props.onAssetSearchCreated(null, zoomToExtent);
                console.log('Failed to get create asset search. Code = ' + response.status);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    function toggleStatusDropdown(event: any) {
        setShowStatusDropdown(!showStatusDropdown)
    }

    async function onStatusUpdated(statusId: number) {
        setShowStatusDropdown(false);
        try {
            var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'managementArea/statusById/' + statusId);
            var maStatus = response.data as IManagementAreaStatus;
            var updatedManagementAreaDetail = { ...managementAreaDetail, statusColor: maStatus.color, statusName: maStatus.name } as IManagementAreaDetail;
            setManagementAreaDetail(updatedManagementAreaDetail);
            props.onManagementAreaStatusUpdated();
        }
        catch (e) {
            console.log("Could not retrieve the updated new management area status details.");
        }
    }

    useEffect(() => {
      async function getManagementAreaDetail() {
        try {
          var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'managementArea/detail/' + props.managementAreaId);

          setManagementAreaDetail(response.data as IManagementAreaDetail);
        } catch (e) {
          console.log(e);
          setManagementAreaDetail(null);
        }
      }

      setManagementAreaDetail(null);
      setShowStatusDropdown(false);

      if (props.managementAreaId !== lastManagementAreaIdRef.current ) {
        lastManagementAreaIdRef.current = props.managementAreaId;
        if (props.managementAreaId > 0) {
            getManagementAreaDetail();
        }
      }
    }, [props.managementAreaId]);
   
    useEffect(() => {
        if (props == null || props.cycleId == '0' || props.managementAreaId == 0)
            return;

        setAssetSearch(null);
        getFilteredSpanList();
        setShowStatusDropdown(false);
    }, [props.cycleId, props.selectedFilterValues, props.managementAreaId]);


    useEffect(() => {
        if (props.isSameManagementAreaReClicked) {
            setShowStatusDropdown(false);
        }
    }, [props.isSameManagementAreaReClicked]);

  return (
    <div style={{ height: '100%' }}>
      <Box display="flex" style={{ textAlign: 'left', fontWeight: 400, height: '100%' }}>
        {managementAreaDetail == null && (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
            <LinearProgress style={{ width: '260px', color: 'red', marginBottom: '8px' }} />
          </Box>
        )}
        {managementAreaDetail != null && (
           <div>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                    <Box className={styles.itemHeader}>
                    <Typography variant="subtitle1">Management Area</Typography>
                    </Box>
                    <Box className={styles.itemContent}>
                    <Typography variant="h5">{managementAreaDetail.managementAreaName}</Typography>
                    </Box>
                </Grid>

                <Grid item xs={7}>
                  {props.canEditMaStatus &&
                      <div>
                        <Chip
                            size="medium"
                            variant="filled"
                            style={{ backgroundColor: managementAreaDetail.statusColor, color: "#161616" }}
                            icon={showStatusDropdown ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            sx={{
                                '& .MuiChip-icon': {
                                    color: '#161616',
                                },
                                '& .MuiChip-label': {
                                    color: '#161616',
                                    fontWeight: 600
                                }
                            }}
                            label={managementAreaDetail.statusName}
                            onClick={(e: any) => toggleStatusDropdown(e) }
                       />
                        <ManagementAreaStatusDropdown
                            managementAreaId={props.managementAreaId}
                            onStatusUpdated={(statusId: number) => onStatusUpdated(statusId)}
                            isOpen={showStatusDropdown}
                        />
                       </div>}
                  {!props.canEditMaStatus &&
                        <Box sx={{ marginTop: '30px' }}>
                            <Chip
                                size="medium"
                                variant="filled"
                                label={managementAreaDetail.statusName}
                                style={{ backgroundColor: managementAreaDetail.statusColor, color: '#161616', fontWeight: 600 }} />
                        </Box>}
                </Grid>

                <Grid item xs={3}>
                    <Box className={styles.itemHeader}>
                    <Typography variant="subtitle2">Region</Typography>
                    </Box>
                    <Box className={styles.itemContent}>
                    <Typography variant="subtitle1">{managementAreaDetail.regionName}</Typography>
                    </Box>
                </Grid>

                <Grid item xs={3}>
                    <Box className={styles.itemHeader}>
                    <Typography variant="subtitle2">Type</Typography>
                    </Box>
                    <Box className={styles.itemContent}>
                    <Typography variant="subtitle1">{managementAreaDetail.type}</Typography>
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Box className={styles.itemHeader}>
                    <Typography variant="subtitle2">Total spans</Typography>
                    </Box>
                    <Box className={styles.itemContent}>
                    <Typography variant="subtitle1">{managementAreaDetail.assetCount}</Typography>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Divider light={true} />
                </Grid>

                <Grid item xs={12}>
                    <ProgramList managementAreaId={managementAreaDetail.managementAreaId} />
                </Grid>
        
                <Grid item xs={12}>
                    <Box className={styles.itemHeader}>
                    <Typography variant="subtitle2">Span Completion Across All Programs</Typography>
                    </Box>
                    <AssetChart
                    completedAssets={managementAreaDetail.completedAssetCount}
                    inProgressAssets={managementAreaDetail.assetCount - managementAreaDetail.completedAssetCount}
                    title={managementAreaDetail.completedAssetCount + ' Spans Complete'}
                    aspectRatio={3}
                    />
                </Grid>

                <Grid item xs={3} alignSelf="start">
                    <Box className={styles.itemHeader}>
                    <Typography variant="subtitle2">Span list</Typography>
                    </Box>
                </Grid>

                <Grid item xs={1} alignSelf="start" justifyContent="flex-end">
                    <Box>
                        {isFilterAssetSensitive(props.selectedFilterValues) && (
                        <NoMaxWidthTooltip placement="top-start" title="A filter has been applied that limits the spans shown in this list">
                                <IconButton style={{ marginLeft: -35, height: 12, width: 12, backgroundColor: '#FFFFFF', color: '#3485FE' }}>
                                <InfoIcon />
                                </IconButton>
                            </NoMaxWidthTooltip>
                        )}
                    </Box>
                </Grid>

                <Grid item alignSelf="end" justifyContent="flex-end">
                    <Box>
                        <IconButton onClick={(e: any) => setShowSpanList(!showSpanList)}  style={{ marginLeft: 240, height: 17, width: 17 }}>
                            {showSpanList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>
                </Grid>

                {showSpanList && assetSearch != null && (
                    <Grid item xs={12}>
                        <Box className={styles.itemContent}>
                            <AssetList
                                cycleId={props.cycleId}
                                managementAreaId={props.managementAreaId}
                                totalAssetCount={managementAreaDetail.assetCount}
                                onAssetSelected={(asset: IAsset) => props.onAssetSelected(asset)}
                                assetSearch={assetSearch}
                            /> 
                        </Box>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Divider light={true} />
                </Grid>

                <Grid item xs={3} alignSelf="start">
                    <Box className={styles.itemHeader}>
                        <Typography variant="subtitle2">Approvals</Typography>
                    </Box>
                </Grid>

                <Grid item alignSelf="end" justifyContent="flex-end">
                    <Box>
                        <IconButton onClick={(e: any) => setShowApprovals(!showApprovals)} style={{ marginLeft: 275, height: 17, width: 17 }}>
                            {showApprovals ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>
                </Grid>

                {spanListSearchGuid && showApprovals && (
                    <Grid item xs={12}>
                    <Box>
                        <ApprovalResults
                            cycleId={props.cycleId}
                            managementAreaId={props.managementAreaId}
                            spanListSearchGuid={spanListSearchGuid}
                            selectedAssetSearchFilters={selectedAssetSearchFilters}
                            isApprovalRefreshRequired={props.isApprovalRefreshRequired}
                            onAssetSelected={(asset: IAsset) => props.onAssetSelected(asset)}
                            onApprovalSelected={(approval: IApproval) => props.onApprovalSelected(approval)}
                            onApprovalResultsUpdated={() => props.onApprovalResultsUpdated()}
                        />
                    </Box>
                    </Grid>
                )}
              </Grid>
        </div>
      )}
      </Box>
    </div>
  );
}

import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import styles from './CmplyContainer.module.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Card, CardActions, CardContent, LinearProgress, Link, Menu, MenuItem } from '@mui/material';
import Typography from '@mui/material/Typography';
import ArticleIcon from '@mui/icons-material/Article';

// Components
import usePrevious from '../../Common/Common';
import { MapBaseLayerSelector } from '../../MapControls/MapBaseLayerSelector/MapBaseLayerSelector';
import { Map } from '../../Map/Map';
import { TabPanel, a11yProps } from '../../TabPanel/TabPanel';
import { Dashboard } from '../../Dashboard/Dashboard';
import { ManagementAreaSearch } from '../../ManagementAreaSearch/ManagementAreaSearch/ManagementAreaSearch';
import ProductMenu from '../../ProductMenu/ProductMenu';
import CycleSelector from '../../CycleSelector/CycleSelector';
import { Insights } from '../../Insights/Insights';
import { MapToolbar } from '../../MapControls/MapToolbar/MapToolbar';
import { LayerList } from '../../MapControls/LayerList/LayerList';
import { MapPicker } from '../../MapControls/MapPicker/MapPicker';
import { ManagementAreaSearchResults } from '../../ManagementAreaSearch/ManagementAreaSearchResults/ManagementAreaSearchResults';
import { ManagementAreaDetail } from '../../ManagementAreaDetail/ManagementAreaDetail';
import { ShapefileDataDetails } from '../../MapControls/ShapefileDataDetails/ShapefileDataDetails';
import { AssetDetail } from '../../AssetDetail/AssetDetail';
import { AssetTaskDetail } from '../../AssetTaskDetail/AssetTaskDetail';
import { ApprovalDetail } from '../../ApprovalDetail/ApprovalDetail';
import { UserIcon } from "../../UserIcon/UserIcon";

// Icons
import cmplyIcon from '../../../images/cmply-icon.png';
import altavecIcon from '../../../images/altavec-icon.png';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// Models
import { ICoordinate } from '../../../models/ICoordinate';
import { IMapBaseLayer } from '../../../models/IMapBaseLayer';
import { GeometryType, IMapLayer } from '../../../models/IMapLayer';
import { IMapClick } from '../../../models/IMapClick';
import { IOpenLayersPositionData } from '../../../models/IOpenLayersPositionData';
import { IUserPreference } from '../../../models/IUserPreference';
import { ICycle } from '../../../models/ICycle';
import { IOrganisationShapefile } from '../../../models/IOrganisationShapefile';
import { UserPreference } from '../../../Enum/UserPreference';
import { IMapPickerConfig } from '../../../models/IMapPickerConfig';
import { IShapefileData } from '../../../models/IShapefileData';
import { IShapefileDataDetailsConfig } from '../../../models/IShapefileDataDetailsConfig';
import { IManagementAreaSearchCreateResponse } from '../../../models/IManagementAreaSearchCreateResponse';
import { IManagementArea } from '../../../models/IManagementArea';
import { IApproval } from '../../../models/IApproval';

// Services
import { getAuthorizationData, fetchWithAuthorisationHeader, postWithAuthorisationHeader, getTokenClaims } from '../../../services/AuthenticationService';

// Config
import settings from '../../../config';
import { IManagementAreaSearchFilters } from '../../../models/IManagementAreaSearchFilters';
import { IAssetSearchCreateResponse } from '../../../models/IAssetSearchCreateResponse';
import { WarningSnackbar } from '../../WarningSnackbar/WarningSnackbar';
import { ISnackbarConfig } from '../../../models/ISnackbarConfig';
import { Close } from '@mui/icons-material';
import { IManagementAreaProgramProgressConfig } from '../../../models/IManagementAreaProgramProgressConfig';
import { ManagementAreaHoverDetail } from '../../ManagementAreaHoverDetail/ManagementAreaHoverDetail';
interface ICmplyContainerProps {}

const mapLayerIconPath: string = process.env.PUBLIC_URL + '/images/MapLayerIcons/';
const POLYGON_ICON_PREFIX: string = 'Frame_13';

type Credentials = {
  organisationId: number | null;
  userPermissions: string[];
  canAims: boolean;
  canVaa: boolean;
  canCmply: boolean;
  userFirstName: string | null;
  userSurname: string | null;
  userEmail: string | null;
};

export const CmplyContainer = (props: ICmplyContainerProps) => {
  document.title = settings.HTML_TAG_TITLE;

  enum TabIndex {
    Home = 0,
    Dashboard,
    Insights
  }

  const [urlParams] = useSearchParams();
  const navigate = useNavigate();

  // State
  const [mapDataLayers, setMapDataLayers] = useState<IMapLayer[][]>([
    [{
        group: 'ManagementAreaSearchResult',
        label: 'Management Areas',
        thumbnail: process.env.PUBLIC_URL + '/images/MaLayerIcon.png',
        hideFromMenu: false,
        min: 0,
        max: 100, //MAs are shown at all zoom levels
        tiled: false,
        zOrder: 900,
        isActive: false,
        isAvailable: true,
        stateIsStatic: false,
        onVisibilityToggle: () => { },
        geometryType: GeometryType.Polygon,
      }],
      [{
        group: 'ManagementAreaSearchResultPrimaryProgramProgress',
        label: 'Management Areas Program Progress',
        thumbnail: process.env.PUBLIC_URL + '/images/MaLayerIcon.png',
        hideFromMenu: false,
        min: 0,
        max: 100, //MAs are shown at all zoom levels
        tiled: false,
        zOrder: 900,
        isActive: true,
        isAvailable: true,
        stateIsStatic: false,
        onVisibilityToggle: () => {},
        geometryType: GeometryType.Polygon,
    }],
    [{
        group: 'SelectedManagementArea',
        label: 'Selected Management Area',
        thumbnail: '',
        hideFromMenu: true,
        min: 0,
        max: 100, //MAs are shown at all zoom levels
        tiled: false,
        zOrder: 901,
        isActive: true,
        isAvailable: true,
        stateIsStatic: false,
        onVisibilityToggle: () => {},
        geometryType: GeometryType.Polygon,
    }],
    [{
        group: 'SelectedManagementAreaAssets',
        label: 'Management Area Assets',
        thumbnail: process.env.PUBLIC_URL + '/images/AssetLayerIcon.png',
        hideFromMenu: false,
        min: 0,
        max: 100,
        tiled: false,
        zOrder: 901,
        isActive: true,
        isAvailable: true,
        stateIsStatic: false,
        onVisibilityToggle: () => {},
        geometryType: GeometryType.Line,
    }],
    [{
        group: 'SelectedManagementAreaHighlightedAssets',
        label: 'Management Area Highlighted Assets',
        thumbnail: process.env.PUBLIC_URL + '/images/HighlightedAssetLayerIcon.png',
        hideFromMenu: false,
        min: 0,
        max: 100,
        tiled: false,
        zOrder: 901,
        isActive: true,
        isAvailable: true,
        stateIsStatic: false,
        onVisibilityToggle: () => {},
        geometryType: GeometryType.Line,
    }], 
    [{
        group: 'SelectedAsset',
        label: 'Selected Asset',
        thumbnail: '',
        hideFromMenu: true,
        min: 0,
        max: 100,
        tiled: false,
        zOrder: 1001,
        isActive: true,
        isAvailable: true,
        stateIsStatic: false,
        onVisibilityToggle: () => { },
        geometryType: GeometryType.Point,
    }]
  ]);
  const [lastMapClick, setLastMapClick] = useState<IMapClick | null>(null);
  const [lastMapPosition, setLastMapPosition] = useState<ICoordinate | null>(null);
  const [lastOpenLayersPositionData, setLastOpenLayersPositionData] = useState<IOpenLayersPositionData | null>(null);
  const [refreshMapLayersFlag, setRefreshMapLayersFlag] = useState(0);
  const [isSelectModeEnabled, setIsSelectModeEnabled] = useState(false);
  const [currentBaseLayer, setCurrentBaseLayer] = useState(localStorage.getItem('SelectedBaseMap')?.toString() ?? 'Dark');
  const [showMapBaseLayerPicker, setShowMapBaseLayerPicker] = useState(false);
  const [showNetworkShapefileVisibilityPanel, setShowNetworkShapefileVisibilityPanel] = useState(false);

  const [tabIndex, setTabIndex] = useState(TabIndex.Home);
  const [isLoadingSearchResults, setIsLoadingSearchResults] = useState(false);
  const [managementAreaSearch, setManagementAreaSearch] = useState<IManagementAreaSearchCreateResponse | null>(null);
  const [managementAreaSearchZoomExtent, setManagementAreaSearchZoomExtent] = useState(false);
  const [tableauUrls, setTableauUrls] = useState([]);
  const [selectedManagementAreaId, setSelectedManagementAreaId] = useState(0);
  const [selectedManagementAreaWkt, setSelectedManagementAreaWkt] = useState<string | null>(null);
  const [showSelectedManagementAreaDetails, setShowSelectedManagementAreaDetails] = useState(false);
  const [showManagementAreaSearchResults, setShowManagementAreaSearchResults] = useState(false);
  const [assetSearch, setAssetSearch] = useState<IAssetSearchCreateResponse | null>(null);
  const [assetSearchZoomExtent, setAssetSearchZoomExtent] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(0);
  const [selectedAssetWkt, setSelectedAssetWkt] = useState<string | null>(null);
  const [selectedAssetTaskId, setSelectedAssetTaskId] = useState(0);
  const [selectedApprovalId, setSelectedApprovalId] = useState(0);
  const [isApprovalRefreshRequired, setIsApprovalRefreshRequired] = useState(true);
  const [showSearchRefreshWarning, setShowSearchRefreshWarning] = useState(false);
  const [refreshSearchResultsFlag, setRefreshSearchResultsFlag] = useState(0);
  const [searchRefreshWarningConfig, setSearchRefreshWarningConfig] = useState<ISnackbarConfig | null>(null);
  const [managementAreaProgramProgressConfig, setManagementAreaProgramProgressConfig] = useState<IManagementAreaProgramProgressConfig | null>(null);
  const [settingsMenuAnchor, setSettingsMenuAnchor] = useState<any | null>(null);
  const [isSameManagementAreaReClicked, setIsSameManagementAreaReClicked] = useState(false);
  const [closeManagementAreaHoverBox, setCloseManagementAreaHoverBox] = useState(false);

  const [selectedCycle, setSelectedCycle] = useState<ICycle>({
    id: '0',
    name: '',
    startDate: '',
  });

  const [credentials, setCredentials] = useState<Credentials>({
    organisationId: null,
    userPermissions: [],
    canAims: false,
    canVaa: false,
    canCmply: false,
    userFirstName: null,
    userSurname: null,
    userEmail: null
  });

  const [orgShapefileLayers, setOrgShapefileLayers] = useState<IMapLayer[] | null>(null);
  const [mapPickerConfig, setMapPickerConfig] = useState<IMapPickerConfig | null>(null);
  const [shapefileDataDetailsConfig, setShapefileDataDetailsConfig] = useState<IShapefileDataDetailsConfig | null>(null);
  const maDetailContainerNodeRef = useRef<any | null>(null);

  const [selectedFilterValues, setSelectedFilterValues] = useState<IManagementAreaSearchFilters | null>(null);

  const [baseMapLayers] = useState<IMapBaseLayer[]>([
    { baseLayerId: 1, baseLayerTitle: 'Dark', active: false, baseLayerName: 'dark-v10', source: 'mapbox', thumbnailUrl: process.env.PUBLIC_URL + '/images/BaseMapLayers/Dark.png' },
    { baseLayerId: 2, baseLayerTitle: 'Light', active: false, baseLayerName: 'light-v10', source: 'mapbox', thumbnailUrl: process.env.PUBLIC_URL + '/images/BaseMapLayers/Light.png' },
    { baseLayerId: 3, baseLayerTitle: 'Outdoor', active: false, baseLayerName: 'outdoors-v10', source: 'mapbox', thumbnailUrl: process.env.PUBLIC_URL + '/images/BaseMapLayers/Outdoor.png' },
    { baseLayerId: 4, baseLayerTitle: 'Satellite', active: false, baseLayerName: 'satellite-streets-v10', source: 'mapbox', thumbnailUrl: process.env.PUBLIC_URL + '/images/BaseMapLayers/Satellite.png' },
    { baseLayerId: 5, baseLayerTitle: 'Road', active: false, baseLayerName: 'streets-v10', source: 'mapbox', thumbnailUrl: process.env.PUBLIC_URL + '/images/BaseMapLayers/Road.png' },
  ]);

  const [mapOptions] = useState<any>({
    wmsApiUrl: settings.CMPLY_API_URL + 'map/wmsBase64',
    mapBoxBaseUrl: 'https://api.mapbox.com/styles/v1/mapbox/{baselayerName}/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZ3RtYXBib3giLCJhIjoiY2l3bG9wazQxMDA0bDJ5cXBleHBjbzJrbSJ9._2U0Hn3TbgoLXMsSGP_QWw',
    openTopoMapBaseUrl: 'https://a.tile.opentopomap.org/{z}/{x}/{y}.png',
    dataProjection: 'EPSG:4326',
    mapProjection: 'EPSG:900913',
  });
  
  async function getAuthData() {
    // Get permissions and roles from Server. If fetch returns 401, it will try to refresh (interceptor). If refresh fails, it will redirect to Aims3d
    await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'authorisation').then((res: any) => {
      if (res && res.status !== 200) {
        throw 'Failed to get authorisation data';
      }

      // Get the authorisation data from local storage and update it with the permission and roles from Server
      // For code to get here, the fetch was successful as it would have redirected if not.
      var existingAuthData = getAuthorizationData();
      existingAuthData.organisationId = res.data.organisationId;
      existingAuthData.organisationName = res.data.organisationName;
      existingAuthData.roles = res.data.roles;
      localStorage.setItem('aims3d.authorizationData', JSON.stringify(existingAuthData));

      // Get the claims from the token stored in local storage
      var claims = getTokenClaims();
      var products = claims['products'];
      setCredentials({
        organisationId: existingAuthData.organisationId,
        userPermissions: res.data.userPermissions,
        canAims: products.includes('AIMS 3D'),
        canVaa: products.includes('AIMS VAA'),
        canCmply: products.includes('AIMS CMPLY'),
        userFirstName: res.data.userFirstname,
        userSurname: res.data.userSurname,
        userEmail: res.data.userEmail
      });
    });
  }

    const getOrgShapefileLayers = () => {
        setOrgShapefileLayers([]);
        fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'networkshapefile/getShapefileLayers/').then((res) => {
          if (res.status !== 200) return;
          let shapefileLayers: IOrganisationShapefile[] = res.data as IOrganisationShapefile[];

          if (!shapefileLayers) return;
          //todo z order sorting
          //map layers to seperate array for diff to standard layers
          let z = 50;
          let layers: IMapLayer[] = shapefileLayers.map((x) => {
            let y: IMapLayer = {
              group: 'Shapefiles',
              thumbnail: `${mapLayerIconPath}${x.geometryType === 'line' ? x.lineTypeStyle : (x.geometryType === 'polygon' ? POLYGON_ICON_PREFIX : x.iconNameStyle)}${x.shapeColorStyle ? '_' + x.shapeColorStyle : ''}.png`,
              label: x.mapLayerName,
              hideFromMenu: false,
              min: settings.CONTEXTUAL_MAP_LAYER_MIN_ZOOM_LEVEL,
              max: 23,
              tiled: false,
              zOrder: 50,
              isActive: x.isVisibleByDefault,
              isAvailable: true,
              stateIsStatic: true,
              onVisibilityToggle: () => {},
              networkShapefileId: x.networkShapefileId,
              geometryType: x.geometryType === 'line' ? GeometryType.Line : x.geometryType === 'polygon' ? GeometryType.Polygon : GeometryType.Point,
            };
            return y;
          });
      
          setOrgShapefileLayers(layers);
        });
  };

  const updateMapDataLayerActiveState = (group: string, label: string, checked: boolean) => {
      //note this doesnt check for duplicate labels.
      let deepClone = JSON.parse(JSON.stringify(mapDataLayers)) as IMapLayer[][];
      deepClone.forEach(layerGroup => {
          for (var i = 0; i < layerGroup.length; i++) {
              if (layerGroup[i].group === "Shapefiles") {
                  //if shapefile group, need to check label as they are dynamically
                  //generated under the one mapserver group
                  for (var j = 0; j < layerGroup.length; j++) {
                      if (layerGroup[j].label === label) {
                          layerGroup[j].isActive = checked;
                          break;
                      }
                  }
                  break;
              }
              else if (layerGroup[i].group === group) {
                  //if any member of a layer set has the matching group name, set all members
                  for (var j = 0; j < layerGroup.length; j++) {
                      layerGroup[j].isActive = checked;
                  }
                  break;
              }
          }
      })

      //update the state array
      setMapDataLayers(deepClone);

    }

    const updateMapDataLayerAvailability = (prevStateOpenLayersPositionData: IOpenLayersPositionData | null) => {
        if (lastOpenLayersPositionData && prevStateOpenLayersPositionData
            && lastOpenLayersPositionData.zoom != prevStateOpenLayersPositionData.zoom) {
            //update map layer control from Mapzoom
            let deepClone = JSON.parse(JSON.stringify(mapDataLayers)) as IMapLayer[][];
            deepClone.forEach(layerGroup => {
                //layer is available if zoom is within layer arrays

                //first get min and max zoom extents for group
                let min = 99; let max = 0;
                layerGroup.forEach(l => {
                    min = l.min < min ? l.min : min;
                    max = l.max > max ? l.max : max;
                });
                layerGroup.forEach(l => {
                    if (lastOpenLayersPositionData
                        && lastOpenLayersPositionData.zoom >= min
                        && lastOpenLayersPositionData.zoom <= max) {
                        layerGroup.forEach(l => {
                            l.isAvailable = true;
                        })
                    } else {
                        layerGroup.forEach(l => {
                            l.isAvailable = false;
                        })
                    }
                });
            }
            )
            //update the state array
            setMapDataLayers(deepClone);
        }
    }
    const prevStateOpenLayersPositionData = usePrevious(lastOpenLayersPositionData);

    useEffect(() => {
        if (lastOpenLayersPositionData != null && prevStateOpenLayersPositionData) {
          
            updateMapDataLayerAvailability(prevStateOpenLayersPositionData)
        }
    }, [lastOpenLayersPositionData]);
  useEffect(() => {
    if(orgShapefileLayers != null){
      let origArray = [...mapDataLayers];
      setMapDataLayers((mapLayers) => [...mapDataLayers, orgShapefileLayers]);
    }
  }, [orgShapefileLayers]);

  const getUserPreferences = () => {
    fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'preference').then((res) => {
      if (res && res.status !== 200) return;
      var preferenceData: IUserPreference[] = res.data;
      var lastAccessedCyclePreference = preferenceData.find((i) => i.preferenceName == UserPreference.LAST_ACCESSED_CYCLE);
      if (lastAccessedCyclePreference) {
         setSelectedCycle({
          id: lastAccessedCyclePreference.preferenceValue,
          name: '',
          startDate:'',
         });
      }
    });
  };

  const onFilterValuesChange = (filterValues: IManagementAreaSearchFilters) => {
      setSelectedFilterValues(filterValues);
  };

  useEffect(() => {
    let token = urlParams.get('token');
    let refresh_token = urlParams.get('refresh_token');
    let selectedTab = urlParams.get('navigation');
    if (token != null && refresh_token != null) {
      // If a token has been passed in the querystring store it in local storage
      let accessToken = window.atob(token);
      let refreshToken = window.atob(refresh_token);

      localStorage.setItem('aims3d.authorizationData', JSON.stringify({ token: accessToken, refreshToken: refreshToken }));
    }

    let authData = getAuthorizationData();
    if (authData) {
      getAuthData()
        .then((res) => {
          let wnd = window as any;
          wnd.tokenChangedCallBack = this;
          getOrgShapefileLayers();
          getUserPreferences();
        })
        .catch((error) => {
           window.location.href = settings.AIMS3D_URL + '#/home?product=AIMSCMPLY';
        });
    } else {
      window.location.href = settings.AIMS3D_URL + '#/home?product=AIMSCMPLY';
    }

    if(selectedTab === 'dashboard') setTabIndex(TabIndex.Dashboard);
    else if(selectedTab === 'insights') setTabIndex(TabIndex.Insights);

    navigate('');
  }, []);

  const onBaseMapLayerSelectedHandler = (baseMapLayer: string) => {
    setCurrentBaseLayer(baseMapLayer);
    //updateUserPreference(UserPreference.LAST_SELECTED_BASE_MAP, baseMapLayer);
    localStorage.setItem('SelectedBaseMap', baseMapLayer);
  };


  const onShapeFileDataSelectedFromMap = (shapefileData: IShapefileData, screenX: number, screenY: number) => {
      
      let ids: number[] = [Number(shapefileData.id)];
      let fetchUrl = settings.CMPLY_API_URL + "networkshapefile/fetchShapefilesByShapefileDataIds";
       
      postWithAuthorisationHeader(fetchUrl, ids).then(res => {
          if (res.data && res.status === 200) {
              let foundShapefileData: IShapefileData = { ...shapefileData, ...res.data[0] as IShapefileData };      
              let shapefileDataDetailsConfig: IShapefileDataDetailsConfig = { shapefileData: foundShapefileData, screenX: screenX, screenY: screenY };
              setShapefileDataDetailsConfig(shapefileDataDetailsConfig);
          }

      })
          .catch(err => {
              console.log('Error: Failed to get ' + fetchUrl + ' with status code ' + err.status);
          });
  }


  const handleShapefileDataAttributesPopupState = (e: any) => {
      if (shapefileDataDetailsConfig) {
          setShapefileDataDetailsConfig(null);
      }
  }
  const onManagementAreaSearchCreatedHandler = (newManagementAreaSearch: IManagementAreaSearchCreateResponse, zoomToExtent: boolean) => {
        setManagementAreaSearch(newManagementAreaSearch);
        setManagementAreaSearchZoomExtent(zoomToExtent);
        onManagementAreaUnselected();
        setShowManagementAreaSearchResults(false);
        setShowSearchRefreshWarning(false);
        setCloseManagementAreaHoverBox(true);
  }

  const onManagementAreaSelected = (wkt:string, managementAreaId: number ) => {
        managementAreaId == selectedManagementAreaId ? setIsSameManagementAreaReClicked(true) : setIsSameManagementAreaReClicked(false);
        setSelectedManagementAreaWkt(wkt);
        setSelectedManagementAreaId(managementAreaId);
        setShowSelectedManagementAreaDetails(true);
        setShowManagementAreaSearchResults(false);
        setCloseManagementAreaHoverBox(true);
        onAssetUnselected();
    }

    const onManagementAreaHover = (managementAreaId: number, screenCoordinates: number[]) => {
        setCloseManagementAreaHoverBox(false);
         //No need to open a hover box if the MA is still selected
        if (selectedManagementAreaId != managementAreaId) {
            setManagementAreaProgramProgressConfig({ managementAreaId: managementAreaId, screenX: screenCoordinates[0], screenY: screenCoordinates[1] });
        }
    }

  const onAssetSearchCreated = (newAssetSearch: IAssetSearchCreateResponse, zoomToExtent: boolean) => {
        setAssetSearch(newAssetSearch);
  }

  const onManagementAreaUnselected = () => {
      setSelectedManagementAreaWkt(null);
      setSelectedManagementAreaId(0);
      setShowSelectedManagementAreaDetails(false);
      onAssetUnselected();
      setSelectedApprovalId(0);
    }

  const onAssetSelected = (assetId: number, wkt:string ) => {
      setSelectedAssetId(assetId);
      setSelectedAssetWkt(wkt);
      setShowSelectedManagementAreaDetails(false);
      setSelectedAssetTaskId(0);
      setSelectedApprovalId(0);
  }

  const onAssetUnselected = () => {
      setSelectedAssetId(0);
      setSelectedAssetWkt(null);
      setSelectedAssetTaskId(0);
      setSelectedApprovalId(0);
  }

  const onManagementAreaBackButtonClicked = () => {
      setShowSelectedManagementAreaDetails(false);
      setShowManagementAreaSearchResults(true);
      setIsSameManagementAreaReClicked(false); 
  }

  const showRefreshSearchResultsAlert = () => {
      const actionLink = (
        <React.Fragment>
            <Link style={{ fontSize: 14, marginRight: 15 }} sx={{ cursor: 'pointer' }}  onClick={() => setRefreshSearchResultsFlag(refreshSearchResultsFlag + 1)}>
                REFRESH
            </Link>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => { setShowSearchRefreshWarning(false); setSearchRefreshWarningConfig(null); }}>
                <Close fontSize="small" />
            </IconButton>
        </React.Fragment>
      );

      setSearchRefreshWarningConfig({ messageType: 'warning', message: 'Search list needs to be refreshed to show the correct results', verticalAnchorOrigin: 'bottom', autoHideDuration: 5000, action: actionLink });
      setShowSearchRefreshWarning(true);
  }

  const homeTab = ( 
      <Map
          userEmail={credentials != null && credentials.userEmail ? credentials.userEmail : ''}
          mapOptions={mapOptions}
          baseMapLayers={baseMapLayers}
          currentBaseLayer={currentBaseLayer}
          managementAreaSearchZoomExtent={managementAreaSearchZoomExtent}
          managementAreaSearch={managementAreaSearch}
          selectedCycle={selectedCycle}
          selectedManagementAreaId={selectedManagementAreaId}
          selectedManagementAreaWkt={selectedManagementAreaWkt}
          selectedAssetId={selectedAssetId}
          selectedAssetWkt={selectedAssetWkt}
          isSelectModeEnabled={isSelectModeEnabled}
          refreshMapLayersFlag={refreshMapLayersFlag}
          assetSearch={assetSearch}
          assetSearchZoomExtent={assetSearchZoomExtent}
          mapDataLayers={mapDataLayers}
          onMapMoveStart={(e: any) => {
              setShapefileDataDetailsConfig(null);
          }}
          onMapSingleClick={(mapClick: IMapClick) => {
              setLastMapClick(mapClick);
              handleShapefileDataAttributesPopupState(mapClick);
          }}
          onDragBoxEnd={() => { }}
          onMapMoveEnd={(e: ICoordinate, zoom: number) => {
              setLastMapPosition(e);
              setLastOpenLayersPositionData({ centrePoint: e, zoom: zoom });
          }}

          onMapSingleClickObjects={(shapefileDataList: IShapefileData[], screenX: number, screenY: number) => {

              if (shapefileDataList.length === 1) {
                  onShapeFileDataSelectedFromMap(shapefileDataList[0], screenX, screenY); // open shapefile details
              }
              if ((shapefileDataList.length) > 1) {
                  // Use the map picker
                  setMapPickerConfig({ shapefileData: shapefileDataList, screenX: screenX, screenY: screenY });
              }
          }}
          onManagementAreaSelected={(wkt: string, managementAreaId: number) => {
              onManagementAreaSelected(wkt, managementAreaId);
          }}
          onAssetSelected={(wkt: string, assetId: number) => {
              onAssetSelected(assetId, wkt);
          }}
          onManagementAreaHover={(managementAreaId: number, screenCoordinates: number[]) => {
              onManagementAreaHover(managementAreaId, screenCoordinates);
          }}
    />
  );

  const dashboard = <Dashboard cycle={selectedCycle} isDisplayed={tabIndex === TabIndex.Dashboard} userFirstName ={credentials.userFirstName}></Dashboard>
    const insights = <Insights tableauUrls={tableauUrls} cycleId={selectedCycle.id} userEmail={credentials.userEmail}></Insights>

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCycleIdChange = (cycle : ICycle) => {
      setSelectedCycle(cycle);
      setManagementAreaSearch(null);
      //Close any MA hover box if open
      setCloseManagementAreaHoverBox(true);
      onManagementAreaUnselected();
      if (tabIndex == TabIndex.Insights) {
          getTableauUrls();
      }
  };

  const onTabChange = (tabIndex: TabIndex) => {
      setTabIndex(tabIndex);
      if (tabIndex == TabIndex.Insights) {
          getTableauUrls();
      }
  }

  async function getTableauUrls() {
      try {
          var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + "report/tableau/");
          if (response.status === 200) {
              setTableauUrls(response.data.urls);
          }
      }
      catch (e) {
          console.log("error in getting tableau urls", e);
      }
  }

  const noResults = (
      <Box className={styles.noResultsMessageText} display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '90%' }}>
          <Typography className={styles.text} variant="h6" gutterBottom>
              Please select a Cycle
          </Typography>
      </Box>
  );

  const loadingContent = (
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
          <LinearProgress style={{ width: '260px', color: 'red', marginBottom: '8px' }} />
          <span>
              <Typography className={styles.text} variant="body2" gutterBottom>
                  This could take a few seconds
              </Typography>
          </span>
      </Box>
  );

  const searchResultTab: JSX.Element = (
      <Box display="flex" flexDirection="column" height={'100%'}>
          <Box className={styles.searchContainer}>
              <ManagementAreaSearch
                  cycle={selectedCycle}
                  refreshSearchResultsFlag={refreshSearchResultsFlag}
                  onUpdateIsLoadingCheck={(isLoading: boolean) => setIsLoadingSearchResults(isLoading)}
                  onManagementAreaSearchCreated={(newManagementAreaSearch: IManagementAreaSearchCreateResponse, zoomToExtent: boolean) => {
                      onManagementAreaSearchCreatedHandler(newManagementAreaSearch, zoomToExtent);
                  }}
                  onFilterValuesChange = {onFilterValuesChange}
              />
          </Box>
          <Box flexGrow={1} className={styles.managementAreaSearchResultsContainer}>
              <div style={{ display: showSelectedManagementAreaDetails || selectedAssetId > 0 ? 'none' : 'block', height: '100%' }}>
                  {isLoadingSearchResults ? (
                      <Box style={{ height: '100%' }}>{loadingContent}</Box>
                  ) : (
                      <ManagementAreaSearchResults
                          cycleId={selectedCycle.id}
                          managementAreaSearch={managementAreaSearch}
                          selectedManagementAreaId={selectedManagementAreaId}
                          selectedFilterValues={selectedFilterValues}
                          onManagementAreaSelected={(managementArea: IManagementArea) => {
                              onManagementAreaSelected(managementArea.wkt, managementArea.managementAreaId);
                              setAssetSearchZoomExtent(managementArea.filteredAssetCount > 0 && managementArea.filteredAssetCount < managementArea.totalAssetCount);
                          }}
                      />
                  )}
              </div>
              <div style={{ display: showSelectedManagementAreaDetails ? 'block' : 'none', height: '100%' }}>
                  <Card style={{ height: '100%', overflowY: 'scroll' }}>
                      <CardActions style={{ paddingLeft: 0 }}>
                          <IconButton aria-label="back" style={{ color: '#E7E69E' }}
                              onClick={() => onManagementAreaBackButtonClicked()}>
                              <ArrowBackIcon style={{ height: 18 }} />
                              <span style={{ fontSize: 15 }}>BACK</span>
                          </IconButton>
                      </CardActions>
                      <CardContent>
                          <ManagementAreaDetail
                              managementAreaId={selectedManagementAreaId}
                              isSameManagementAreaReClicked={isSameManagementAreaReClicked}
                              cycleId={selectedCycle.id}
                              selectedFilterValues={selectedFilterValues}
                              canEditMaStatus={credentials.userPermissions.includes('CanManageMAStatus')}
                              isApprovalRefreshRequired={isApprovalRefreshRequired}
                              onAssetSelected={(asset: any) => {
                                  onAssetSelected(asset.id, asset.wkt);
                              }}
                              onAssetSearchCreated={(newAssetSearch: IAssetSearchCreateResponse, zoomToExtent: boolean) => {
                                  onAssetSearchCreated(newAssetSearch, zoomToExtent);
                              }}
                              onApprovalSelected={(approval: IApproval) => setSelectedApprovalId(approval.approvalId)}
                              onApprovalResultsUpdated={() => setIsApprovalRefreshRequired(false)}
                              onManagementAreaStatusUpdated={() => showRefreshSearchResultsAlert()}
                          />
                      </CardContent>
                  </Card>
              </div>
              <div style={{ display: selectedAssetId > 0 ? 'block' : 'none', height: '100%' }}>
                  <AssetDetail
                      assetId={selectedAssetId}
                      managementAreaId={selectedManagementAreaId}
                      cycleId={selectedCycle.id}
                      onClickBack={() => {
                          setShowSelectedManagementAreaDetails(true);
                          onAssetUnselected();
                      }}
                      onAssetTaskSelected={(assetTaskId: number) => setSelectedAssetTaskId(assetTaskId)}
                  />
              </div>
              <AssetTaskDetail
                  assetTaskId={selectedAssetTaskId}
                  assetId={selectedAssetId}
                  managementAreaId={selectedManagementAreaId}
                  cycleId={selectedCycle.id}
                  onClose={() => setSelectedAssetTaskId(0)}
              />
              <ApprovalDetail
                  approvalId={selectedApprovalId}
                  canEdit={credentials.userPermissions.includes('CanEditApprovals')}
                  onClose={() => setSelectedApprovalId(0)}
                  onApprovalUpdate={() => setIsApprovalRefreshRequired(true)}
              />
              {showSearchRefreshWarning && showManagementAreaSearchResults  && <div>
                  <WarningSnackbar config={searchRefreshWarningConfig} />
              </div>}
          </Box>
      </Box>
  );
  
  function LinkTab(props:any) {
    return (
      <Tab
        component="a"
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
            event.preventDefault();
            setShapefileDataDetailsConfig(null)
        }}
        {...props}
      />
    );
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      {tabIndex === TabIndex.Home && (
              <div className={styles.mapBaseLayerSelectorContainer}>
                  <MapBaseLayerSelector baseMapLayers={baseMapLayers} currentBaseLayer={currentBaseLayer} onBaseMapLayerSelected={onBaseMapLayerSelectedHandler} showSelector={showMapBaseLayerPicker} onHideSelector={() => { setShowMapBaseLayerPicker ( false); }} />
        </div>
          )}

      {tabIndex === TabIndex.Home && (
          <div className={styles.mapToolbarContainer}>
              <MapToolbar
                 onShowMapBaseLayerPicker={() => { setShowMapBaseLayerPicker(!showMapBaseLayerPicker); setShowNetworkShapefileVisibilityPanel(false); }}
                 onShowNetworkShapefileVisibilityPanel={() => { setShowNetworkShapefileVisibilityPanel(!showNetworkShapefileVisibilityPanel); setShowMapBaseLayerPicker(false); }}
              />
          </div>
      )}

      {tabIndex === TabIndex.Home && (
        <div className={styles.mapNetworkLayersContainer }>
          <Box display="flex" flexDirection="column" height={'100%'}>             
                      {mapDataLayers.length > 0 && showNetworkShapefileVisibilityPanel &&(
                          <LayerList mapLayers={mapDataLayers} displayPanel={showNetworkShapefileVisibilityPanel}
                     onToggleVisibility={(group: string, label: string, checked: boolean) => {updateMapDataLayerActiveState(group, label, checked) }}
                 />)}                 
            </Box>
        </div>
      )}

      {tabIndex === TabIndex.Home && (
          <div className={styles.mapPickerContainer}>
              <MapPicker
                  config={mapPickerConfig}
                  onClose={() => setMapPickerConfig(null)}
                  onShapefileDataSelected={(shapefileData: IShapefileData, screenX: number, screenY: number) => {
                      setShapefileDataDetailsConfig(null);
                      setMapPickerConfig(null);
                      onShapeFileDataSelectedFromMap(shapefileData, screenX, screenY);
                  }}
              />
          </div>
          )}

          {tabIndex === TabIndex.Home && (
              <div>
                  {managementAreaProgramProgressConfig &&
                      <ManagementAreaHoverDetail
                          config={managementAreaProgramProgressConfig}
                          onClose={() => setManagementAreaProgramProgressConfig(null)}
                          closeHoverBox={closeManagementAreaHoverBox}
                      />}
              </div>
          )}

          <div className={styles.generalSnackbarContainer}>
              {shapefileDataDetailsConfig &&
                  <ShapefileDataDetails
                      config={shapefileDataDetailsConfig}
                      onClose={() => setShapefileDataDetailsConfig(null)}
                  />}
          </div>
      <AppBar position="static" elevation={3}>
        <Toolbar style={{ paddingLeft: 30, paddingRight: 15 }} sx={{ backgroundColor: 'background.paper' }}>
          <Avatar style={{ width: 40, height: 40 }} src={cmplyIcon} />
          <div className={styles.menuIcons}>
            <IconButton color="inherit" onClick={(e: any) => setSettingsMenuAnchor( e.target )} >
              <SettingsIcon style={{ width: 30, height: 30, color: '#6D6D6D' }} />
            </IconButton>
            { credentials != null && credentials.userEmail &&
                <IconButton color="inherit">
                  <UserIcon marginLeft={0} marginRight={0} height={28} width={28} fontSize={12} emailAddress={credentials.userEmail ?? ''} firstName={credentials.userFirstName ?? ''} surname={credentials.userSurname} tooltip={credentials.userFirstName + ' ' + credentials.userSurname + ' ' + credentials.userEmail} />
                </IconButton>
            }
            <IconButton color="inherit" onClick={handleClick} aria-controls={open ? 'account-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined}>
              <Avatar style={{ width: 40, height: 40 }} src={altavecIcon} />
            </IconButton>
          </div>
          <Menu
              anchorEl={settingsMenuAnchor}
              elevation={1}
              keepMounted
              anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
              }}
              transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
              }}
              open={Boolean(settingsMenuAnchor)}
              onClose={(e: any) => setSettingsMenuAnchor( null ) } >
              <MenuItem className={styles.userMenuItem}>CMPLY version: {settings.VERSION as string}</MenuItem>
          </Menu>
        </Toolbar>
        <div className={styles.cycleSelector}>
          <CycleSelector lastAccessedCycleId={selectedCycle.id} onCycleIdChange={onCycleIdChange} organisationId={credentials.organisationId ?? 0}></CycleSelector>
        </div>
          <ProductMenu anchorEl={anchorEl} open={open} handleClose={handleClose} canAims={credentials.canAims} canVaa={credentials.canVaa} />
      </AppBar>
      <Box sx={{ bgcolor: 'background.paper', display: 'flex', height: '100%', width: '100%' }}>
        <Tabs
          orientation="vertical"
          variant="fullWidth"
          value={tabIndex}
          onChange={(e: any, newTabIndex: number) => onTabChange(newTabIndex)}
          aria-label="Cmply tabs"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
           <LinkTab label="Home" value={TabIndex.Home} icon={<HomeIcon />} href={settings.CMPLY_URL + '?navigation=home'} style={{ textTransform: 'none', minWidth: '50%' }} {...a11yProps(0)} />
           {credentials.userPermissions.includes('CanAccessCmplyDashboard') && (
           <LinkTab label="Dashboard" value={TabIndex.Dashboard} icon={<BarChartIcon />} href={settings.CMPLY_URL + '?navigation=dashboard'} style={{ textTransform: 'none', minWidth: '50%' }} {...a11yProps(1)} />
           )}
           <LinkTab label="Insights" value={TabIndex.Insights} icon={<ArticleIcon />} href={settings.CMPLY_URL + '?navigation=insights'} style={{ textTransform: 'none', minWidth: '50%' }} {...a11yProps(2)} />
        </Tabs>
              <TabPanel value={tabIndex} index={TabIndex.Home}>
          {homeTab}
        </TabPanel>
        <TabPanel value={tabIndex} index={TabIndex.Dashboard}>
          {dashboard}
        </TabPanel>
        <TabPanel value={tabIndex} index={TabIndex.Insights}>
          {insights}
        </TabPanel>
      </Box>
      <Box display="flex" flexDirection="column" className={styles.tabsContainer} style={{ width: 450, display: tabIndex === TabIndex.Home ? 'block' : 'none' }}>
        {selectedCycle.id === '0' ? noResults : searchResultTab }
      </Box>
    </Box>
  );
};

import React from 'react';
import OpenLayersMap from './OpenLayersMap'
import styles from './Map.module.css'
import settings from '../../config';

import { ICoordinate } from '../../models/ICoordinate';
import { IMapClick } from '../../models/IMapClick';
import { IMapDragBox } from '../../models/IMapDragBox';

import { fetchWithAuthorisationHeader, getAuthorizationData, getTokenExpiryTime, postWithAuthorisationHeader, refreshToken } from "../../services/AuthenticationService";
import { IMapLayer, GeometryType } from '../../models/IMapLayer';
import { deepClone } from '../Common/Common';
import { IManagementAreaSearchCreateResponse } from '../../models/IManagementAreaSearchCreateResponse';
import { IManagementArea } from '../../models/IManagementArea';
import { ICycle } from '../../models/ICycle';
import { IShapefileData } from '../../models/IShapefileData';
import { IAssetSearchCreateResponse } from '../../models/IAssetSearchCreateResponse';
import { IManagementAreaPolygon } from '../../models/IManagementAreaPolygon';

const defaultZoomLevel = 18;
var newZoomLevel = 18;
const managementAreaSearchDisplayBuffer = 1;
const assetSearchDisplayBuffer = 0.05;
const buffer = 0.1;

interface IMapProps {
    mapOptions: any;
    baseMapLayers: any[];
    currentBaseLayer: string;
    isSelectModeEnabled: boolean;
    managementAreaSearch: IManagementAreaSearchCreateResponse | null;
    managementAreaSearchZoomExtent: boolean;
    assetSearch: IAssetSearchCreateResponse | null;
    assetSearchZoomExtent: boolean;
    selectedCycle: ICycle;
    selectedAssetId: number; 
    selectedAssetWkt: string | null; 
    selectedManagementAreaId: number; 
    selectedManagementAreaWkt: string | null; 
    onMapSingleClick: Function;
    onMapSingleClickObjects: Function;
    onDragBoxEnd: Function;
    onMapMoveEnd: Function;
    onMapMoveStart: Function;
    mapDataLayers: IMapLayer[][] | [][];
    refreshMapLayersFlag: number;
    onManagementAreaSelected: Function;
    onAssetSelected: Function;
    userEmail: string;
    onManagementAreaHover: Function;
};
interface IMapState {
    showManagementAreaPrimaryProgramProgressHoverLayer: boolean;
}
export class Map extends React.Component<IMapProps, IMapState> {
    openLayersMap: OpenLayersMap | null = null;
    pulseInterval:any = null;
    pulseTimeout:any = null;
    initialised: boolean = false;
    constructor(props: IMapProps) {
        super(props);

        this.initaliseState();
    }

    initaliseState() {
        this.state = {
            showManagementAreaPrimaryProgramProgressHoverLayer: true
        }
    }
  // Called when the dom has been rendered
  componentDidMount() {
      
      if ( this.initialised )
          return;

      this.initialised = true;

      try {
          this.openLayersMap = new OpenLayersMap(this.props.mapOptions, this.props.baseMapLayers, 'map', this);          
          this.openLayersMap.updateMapSize();
          this.openLayersMap.setBaseLayer(this.props.currentBaseLayer);  
      }
      catch (e) {
          console.log("ERROR Could not instantiate Map: " + e);
          return;
      }

      //load mapserver layers
      for (var i = 0; i < this.props.mapDataLayers.length; ++i) {
          for (var j = 0; j < this.props.mapDataLayers[i].length; ++j) {
            try {
                this.openLayersMap.createWMSLayer(this.props.mapDataLayers[i][j].group, null, null, 
                                                  this.props.mapDataLayers[i][j].tiled, 
                                                  this.props.mapDataLayers[i][j].zOrder,
                                                  this.props.mapDataLayers[i][j].min, 
                                                  this.props.mapDataLayers[i][j].max);

                this.openLayersMap.showLayer(this.props.mapDataLayers[i][j].group, this.props.mapDataLayers[i][j].isActive); 
                if (this.props.mapDataLayers[i][j].group == 'ManagementAreaSearchResultPrimaryProgramProgress') {
                    this.setState({
                        showManagementAreaPrimaryProgramProgressHoverLayer: this.props.mapDataLayers[i][j].isActive
                    });
                }
              } catch (e) {
                  console.log("ERROR,Could not add mapserver Layer " + this.props.mapDataLayers[i][j].group + ": " + e);
                  return;
              }
          }
        }
    }

    //our custom method of flying and highlighting a point
    focusObjectOnMap(olm:OpenLayersMap, lon:number, lat:number,rgbaColour:string,dataType:string, wkt:string,zoomLevel:number) {
        if (olm === null || lat === undefined || lon === undefined) return;

        olm.panTo(lon, lat);
        olm.zoomTo(zoomLevel);
          
        clearInterval(this.pulseInterval);
        this.pulseInterval = null;

        clearTimeout(this.pulseTimeout);
            
        // Draw vector with animation
        this.pulseInterval = setInterval(() => {
            if (olm == null) return;

            if (dataType === "Line" && wkt != null)
              olm.addLinePulseFeature( rgbaColour, wkt);
            else
              olm.addPulseFeature(lon, lat, rgbaColour);
        }, 1000);
        
        this.pulseTimeout = setTimeout(() => {
            if (this.pulseInterval != null) {
                clearInterval(this.pulseInterval);
                this.pulseInterval = null;
            }
            
            }, 3000);
    }

    //sets z index in place, returns reference to array
    sortMapLayersZIndexFromGeometryType(a:IMapLayer[][], startingZIndex:number):IMapLayer[][] {
        let geomTypeIdx:number = 0;
        for (let i = 0; i < a.length && geomTypeIdx < Object.keys(GeometryType).length/2; i++) {    // length/2 due to TS enum reverse mapping
            for (let j = 0; j < a[i].length; j++) {
                if (a[i][j].geometryType === geomTypeIdx) {
                    a[i][j].zOrder = startingZIndex++;
                }
                if (i === a.length -1 && j === a[i].length -1) {
                    i = 0; 
                    ++geomTypeIdx;
                }
            }
        }
        return a;
    }

    // Called when properties change
    componentDidUpdate(prevProps: IMapProps, prevState: IMapState) {
        if (this.openLayersMap == null)
            return;

        let managementAreaSearchLayers = ['ManagementAreaSearchResult', 'ManagementAreaSearchResultPrimaryProgramProgress'];
      
        //first check if any new map layers need to be loaded
        if (this.props.mapDataLayers.length !== prevProps.mapDataLayers.length) {
            let prevLen = prevProps.mapDataLayers.length;
            //copy new layers
            let sortedNewMapLayers:IMapLayer[][] = deepClone<IMapLayer[][]>(this.props.mapDataLayers.slice(prevLen));

            var maxZIndex: number = settings.CONTEXTUAL_MAP_LAYER_MIN_ZOOM_LEVEL;
            for (var i = 0; i < prevProps.mapDataLayers.length; ++i) {
                for (var j = 0; j < prevProps.mapDataLayers[i].length; ++j) {
                    if(prevProps.mapDataLayers[i][j].zOrder > maxZIndex && prevProps.mapDataLayers[i][j].zOrder < 900)
                    {
                        maxZIndex = prevProps.mapDataLayers[i][j].zOrder;
                    }
                }
            }

            this.sortMapLayersZIndexFromGeometryType(sortedNewMapLayers, maxZIndex);
            
            for (var i = 0; i < sortedNewMapLayers.length; ++i) {
                for (var j = 0; j < sortedNewMapLayers[i].length; ++j) {
                    try {
                        if (!sortedNewMapLayers[i][j]) {
                            throw ("Layer is null");
                        }
                       
                            this.openLayersMap.createWMSLayer(sortedNewMapLayers[i][j].group, null, null,
                                sortedNewMapLayers[i][j].tiled,
                                sortedNewMapLayers[i][j].zOrder,
                                sortedNewMapLayers[i][j].min,
                                sortedNewMapLayers[i][j].max);                      
                        
                    } catch (e) {
                        console.log("ERROR,Could not add mapserver Layer " + sortedNewMapLayers[i][j].group + ": " + e);
                        continue;
                    }
                    
                }
            }      
        }
        
        //next update any changed properties of map layers
        if (this.props.mapDataLayers !== prevProps.mapDataLayers) {
            
            let activeShapefileIds : number[] = [];

             this.props.mapDataLayers.map((layers) => {
               layers.map((l) => {
                 if (l.group === 'Shapefiles' && l.isActive) {
                   activeShapefileIds.push(Number(l.networkShapefileId));
                 }
               });
             });

            for (let i = 0; i < prevProps.mapDataLayers.length; i++) {
                for (let j = 0; j < prevProps.mapDataLayers[i].length; j++) {
                    if (this.props.mapDataLayers[i][j].isActive !== prevProps.mapDataLayers[i][j].isActive && this.props.mapDataLayers[i][j].group !== "Shapefiles") {
                       this.openLayersMap.showLayer(this.props.mapDataLayers[i][j].group, this.props.mapDataLayers[i][j].isActive);    
                     
                        if (this.props.mapDataLayers[i][j].group == 'ManagementAreaSearchResultPrimaryProgramProgress') {
                            this.setState({
                                showManagementAreaPrimaryProgramProgressHoverLayer: this.props.mapDataLayers[i][j].isActive
                            });                         

                        }
                    }
                }
            }
            this.openLayersMap.showLayer("Shapefiles", activeShapefileIds.length > 0);
            this.openLayersMap.updateWMSLayer("Shapefiles", { fileIds: activeShapefileIds });
           
        }

        if (this.props.currentBaseLayer !== prevProps.currentBaseLayer) {
            this.openLayersMap.setBaseLayer(this.props.currentBaseLayer);
        }

        let assetSearchLayers = ['SelectedManagementAreaHighlightedAssets'];
        if (this.props.managementAreaSearch != prevProps.managementAreaSearch) {           
            // An image search has been created or cleared
            // Pass a dummy guid if search is null so that the map layers gets redrawn with no results
            let filter = this.props.managementAreaSearch != null ? { searchId: this.props.managementAreaSearch.searchId, managementAreaId: this.props.selectedManagementAreaId, username: this.props.userEmail } : { searchId: '00000000-0000-0000-0000-a00000000000', managementAreaId: 0, username: this.props.userEmail };
           
            for (var i = 0; i < managementAreaSearchLayers.length; ++i) {
                this.openLayersMap.updateWMSLayer(managementAreaSearchLayers[i], filter);             
            }
            
            // Get the extent and zoom to it
            if (this.props.managementAreaSearch != null && this.props.managementAreaSearch.totalManagementAreasCount > 0 && this.props.managementAreaSearchZoomExtent && this.props.managementAreaSearch.extent !=null) {
                this.openLayersMap.zoomToExtent(
                    this.props.managementAreaSearch.extent.st_xmin + managementAreaSearchDisplayBuffer, // shifting the results to the left to account for the search filter box
                    this.props.managementAreaSearch.extent.st_ymin,
                    this.props.managementAreaSearch.extent.st_xmax,
                    this.props.managementAreaSearch.extent.st_ymax
                );
                // setting zoom level to default for new search 
                newZoomLevel = defaultZoomLevel;               
            }

            //Redraw the Management area primary program hover layer agian
            this.refreshVectorLayer();
            
            let assetfilter = {
                managementAreaId: this.props.selectedManagementAreaId.toString(),
                cycleId: '00000000-0000-0000-0000-a00000000000',
                searchId: '00000000-0000-0000-0000-a00000000000'
            };
            for (var i = 0; i < assetSearchLayers.length; ++i) {

                this.openLayersMap.updateWMSLayer(assetSearchLayers[i], assetfilter);
            }        
        }
      
        if (this.props.assetSearch != prevProps.assetSearch) {
            // An asset search has been created or cleared
            // Pass a dummy guid if search is null so that the map layers gets redrawn with no results
            let filter = {
                managementAreaId: this.props.selectedManagementAreaId.toString(),
                cycleId: this.props.selectedManagementAreaId > 0 ? this.props.selectedCycle.id : '00000000-0000-0000-0000-a00000000000',
                searchId: this.props.assetSearch != null && this.props.assetSearch.searchId !=''? this.props.assetSearch.searchId : '00000000-0000-0000-0000-a00000000000' };

            for (var i = 0; i < assetSearchLayers.length; ++i) {
                this.openLayersMap.updateWMSLayer(assetSearchLayers[i], filter);
            }

            if (this.props.assetSearch != null && this.props.assetSearch.totalAssetsCount > 0 && this.props.assetSearchZoomExtent && this.props.assetSearch.extent != null &&
                this.props.assetSearch.extent.st_xmin + this.props.assetSearch.extent.st_ymin + this.props.assetSearch.extent.st_xmax + this.props.assetSearch.extent.st_ymax !=0 ) {
                this.openLayersMap.zoomToExtent(
                    this.props.assetSearch.extent.st_xmin + assetSearchDisplayBuffer, // shifting the results to the left to account for the search filter box
                    this.props.assetSearch.extent.st_ymin,
                    this.props.assetSearch.extent.st_xmax,
                    this.props.assetSearch.extent.st_ymax
                );
            }
            
        }

        var authData = getAuthorizationData();
        try {
            this.openLayersMap.updateAccessToken(authData.token, getTokenExpiryTime());
        }
        catch (e) {
            console.log("Failed to update access token");
        }

        if (this.props.isSelectModeEnabled !== prevProps.isSelectModeEnabled) {
            this.openLayersMap.enableDragBox(this.props.isSelectModeEnabled);

            if (!this.props.isSelectModeEnabled) {
                this.openLayersMap.removeLayerIfExists('selectionIconLayer');
            }
        }

        if (this.props.selectedAssetId != 0 && this.props.selectedAssetId != prevProps.selectedAssetId && this.props.selectedAssetWkt != null) {
            // An asset has been selected

            // Only zoom if the asset is not completely on the screen
            if ( !this.openLayersMap.isWKTCompletelyWithinMapExtents( this.props.selectedAssetWkt ))
                this.zoomToWktExtent(this.props.selectedAssetWkt, buffer);
        }

        if (this.props.selectedAssetId != prevProps.selectedAssetId) {

            // An asset has been selected or unselected
            let filter = { assetId: this.props.selectedAssetId.toString()};
            this.openLayersMap.updateWMSLayer('SelectedAsset', filter);
        } 

        if (this.props.selectedManagementAreaId != 0 && this.props.selectedManagementAreaId != prevProps.selectedManagementAreaId && this.props.selectedManagementAreaWkt != null) {
            // A management area has been selected

            this.zoomToWktExtent(this.props.selectedManagementAreaWkt, buffer);
        }

        if (this.props.selectedManagementAreaId != prevProps.selectedManagementAreaId) {
            // A management area has been selected or unselected
            let filter = { managementAreaId: this.props.selectedManagementAreaId.toString(), cycleId: this.props.selectedManagementAreaId > 0 ? this.props.selectedCycle.id : '00000000-0000-0000-0000-a00000000000', username: this.props.userEmail };
            this.openLayersMap.updateWMSLayer('ManagementAreaSearchResult', filter);
            this.openLayersMap.updateWMSLayer('ManagementAreaSearchResultPrimaryProgramProgress', filter);
            this.openLayersMap.updateWMSLayer('SelectedManagementArea', filter);
            this.openLayersMap.updateWMSLayer('SelectedManagementAreaAssets', filter);
        } 

        if (this.props.refreshMapLayersFlag > prevProps.refreshMapLayersFlag) {
            //Refresh wms layers layers
        }
    }

    getRGBAFromHexColour(hexColour: string,opactity:number) {
        if ( hexColour == null || hexColour.length !== 6)
            return '';

        return 'rgba(' + this.getHexValFromNumber(hexColour,0,2) + ', ' + this.getHexValFromNumber(hexColour,2,4) + ',' + this.getHexValFromNumber(hexColour,4,6) + ',' + opactity + ')';
    }

    getHexValFromNumber(str: string, start:number, len:number) {
        return parseInt('0x' +  str.substring(start,len));
    }

    // Handle event from OpenLayers
    async onDragBoxEnd(event: IMapDragBox) {

    }

    async onMapSingleClick(event: IMapClick) {

        // Use the getfeatureinfo urls to work out if a management area or asset has been clicked on
        let managementAreaSearchResultUrl = event.visbleLayersFeatureInfoUrls.find(f => f.layer === 'ManagementAreaSearchResultPrimaryProgramProgress');
        if (managementAreaSearchResultUrl == null)
            managementAreaSearchResultUrl = event.visbleLayersFeatureInfoUrls.find(f => f.layer === 'ManagementAreaSearchResult');
        
        let selectedManagementAreaUrl = event.visbleLayersFeatureInfoUrls.find(f => f.layer === 'SelectedManagementArea');
        let selectedManagementAreaAssetsUrl = event.visbleLayersFeatureInfoUrls.find(f => f.layer === 'SelectedManagementAreaAssets');
        let selectedManagementAreaHighlightedAssetsUrl = event.visbleLayersFeatureInfoUrls.find(f => f.layer === 'SelectedManagementAreaHighlightedAssets');

        let minZoomForClickObject = settings.CONTEXTUAL_MAP_LAYER_MIN_ZOOM_LEVEL;

        let maUrl = managementAreaSearchResultUrl ?? selectedManagementAreaUrl; // Select the visible layer to find the MA

        if (maUrl != null ){
            let result = await fetchWithAuthorisationHeader(maUrl.url);
            if (result.status === 200 && result.data != null && result.data.length > 0 && result.data[0].id != null && this.openLayersMap != null) {

                let managementAreaId = parseInt(result.data[0].id);

                if ( managementAreaId > 0 && managementAreaId === this.props.selectedManagementAreaId ){
                                       
                    // Clicked on the currently selected management area.  highlight assets that are matched with filters
                    if (selectedManagementAreaHighlightedAssetsUrl != null) {
                        result = await fetchWithAuthorisationHeader(selectedManagementAreaHighlightedAssetsUrl.url);
                        if (result.status === 200 && result.data != null && result.data.length > 0 && result.data[0].id != null && this.openLayersMap != null) {
                            this.props.onAssetSelected(event.zoom >= minZoomForClickObject ? null : result.data[0].wkt, parseInt(result.data[0].id));
                        }
                    }
                }
                else {
                    this.props.onManagementAreaSelected(event.zoom >= minZoomForClickObject ? null : result.data[0].wkt, managementAreaId );
                }
            }
        }
        let shapefileSearchUrl = event.visbleLayersFeatureInfoUrls.find(f => f.layer === 'Shapefiles');
        if (event.zoom >= minZoomForClickObject) {

            let shapefileDataResults: IShapefileData[] = [];
           
            if ( shapefileSearchUrl != null) {
                //the shapefile layer is visible so call the wms endpoint and get the shapefile data ids from the map
                let result = await fetchWithAuthorisationHeader(shapefileSearchUrl.url);
               
                if (result.status === 200 && result.data != null && result.data.length > 0 && result.data[0].id != null)
                {  
                    result.data.filter((i: any) => !isNaN(i.id)).forEach((i: any) => shapefileDataResults.push(i));
                } else if (result.status !== 200) {
                    console.log('Error: Failed to get ' + shapefileSearchUrl.url + ' with status code ' + result.status);
                }
            }
            
            this.props.onMapSingleClickObjects(shapefileDataResults, event.screenX, event.screenY);   
        }
        if (this.props.onMapSingleClick) {
            // Echo the event out to a component event handler
            this.props.onMapSingleClick(event);
        } 
    }

    onMapMoveStart(e:any) {
        this.props.onMapMoveStart(e);
    }

    // Handle event from OpenLayers
    onMapMoveEnd(event: ICoordinate, zoom:number) {

        //Redraw the Management area primary program hover layer agian
        this.refreshVectorLayer();

        if (this.props.onMapMoveEnd) {
            this.props.onMapMoveEnd(event, zoom);
        }

      //// store zoom settings only if greater than 16, it will be used as new zoom level in focusobjectMap()
      //  if(zoom>clusterZoomLevel)
      //  {newZoomLevel = zoom;}
    }

    onMapPointerMove(event: any) {
        let this_ = this;
        let olMap = this_.openLayersMap;
        if (olMap == null)
            return;
        if (!this.state.showManagementAreaPrimaryProgramProgressHoverLayer)
            return;
        olMap.map.getTargetElement().style.cursor = 'auto';
        // close any previously opened hover details
        this_.props.onManagementAreaHover(0, event.pixel); 

        // Display management area progress on hover 
        event.map.forEachFeatureAtPixel(event.pixel, (feature: any,layer: any) => {
            olMap?.getLayerFeatures('ManagementAreaSearchResultPrimaryProgramProgressHover').forEach((f: any) => {
                if (olMap != null) {
                    if (f.getId() === feature.getId()) {
                        olMap.map.getTargetElement().style.cursor = 'pointer';
                        this_.props.onManagementAreaHover(f.getId(), event.pixel);
                    }
                }
            });
        });
    }
    async getMAPrimaryProgramProgressFeaturesForCurrentMapArea(searchId: string, vectorLayer: any) {       
        if (this.openLayersMap == null)
            return;

        let mapExtentWkt = this.openLayersMap.getWktForMapExtent();
        let vectorUrl = settings.CMPLY_API_URL + "search/fetchByPolygon/";
        let featuresToCreate: IManagementAreaPolygon[] = [];
        let params = {
            searchId: searchId,
            wkt: mapExtentWkt,
        }

        let result = await postWithAuthorisationHeader(vectorUrl, params);
        
        if (result.status === 200 && result.data != null && result.data.length > 0 ) {

            featuresToCreate = result.data;
            this.openLayersMap.addManagementAreaSearchResultPrimaryProgramProgressPolygonFeatures(vectorLayer, featuresToCreate);

        } else if (result.status !== 200) {
            console.log('Error: Failed to get ' + vectorUrl + ' with status code ' + result.status);
        }
    }

    async refreshVectorLayer() {
         if (this.props.managementAreaSearch != null && this.openLayersMap != null && this.state.showManagementAreaPrimaryProgramProgressHoverLayer) {
            this.openLayersMap.removeLayerIfExists('ManagementAreaSearchResultPrimaryProgramProgressHover');
            let vectorLayer = this.openLayersMap.addVectorLayer('ManagementAreaSearchResultPrimaryProgramProgressHover')
            this.getMAPrimaryProgramProgressFeaturesForCurrentMapArea(this.props.managementAreaSearch.searchId, vectorLayer);
        }
         else if (this.props.managementAreaSearch == null && this.openLayersMap != null)
            this.openLayersMap.removeLayerIfExists('ManagementAreaSearchResultPrimaryProgramProgressHover');
    }
     
    async onTokenRefreshRequest() {
        let olMap = this.openLayersMap;

        let accessToken = await refreshToken();
        if (accessToken != null && olMap != null) {
            var authData = getAuthorizationData();
            
            olMap.updateAccessToken(authData.token, getTokenExpiryTime());            

            let layers = olMap.getWMSLayers();
            for (var i = 0; i < layers.length; ++i) {
                olMap.updateWMSLayer(layers[i].get('name'), { t: Date.now() }); 
            }
        } 
    }

    zoomToWktExtent(wkt:string,buffer:number) {

        if ( this.openLayersMap == null )
            return;

        let extent = this.openLayersMap.getBufferedExtentFromWkt(wkt, buffer);
                    
        let xmin = extent[0];
        let xmax = extent[2];
        let ymin = extent[1];
        let ymax = extent[3];

        this.openLayersMap.zoomToExtent(
                xmin,
                ymin,
                xmax,
                ymax
        )
    }

    render() {

        return <div id="map" className={styles.map}> </div>;
    }
}

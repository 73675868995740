import React, { useState, useEffect } from 'react';
import { Box } from '@mui/system';
import ClearIcon from '@mui/icons-material/Clear';
import { Grid, Typography, CircularProgress, Popper, PopperProps, IconButton } from '@mui/material';
import { fetchWithAuthorisationHeader } from '../../services/AuthenticationService';
import settings from '../../config';
import styles from './ManagementAreaHoverDetail.module.css';
import { IManagementAreaProgramProgress } from '../../models/IManagementAreaProgramProgress';
import { IManagementAreaProgramProgressConfig } from '../../models/IManagementAreaProgramProgressConfig';

interface IProps {
    config: IManagementAreaProgramProgressConfig;
    onClose: Function;
    closeHoverBox: Boolean;
}

export function ManagementAreaHoverDetail(props: IProps) {
    const [managementAreaHoverDetails, setManagementAreaHoverDetails] = useState<IManagementAreaProgramProgress | null>(null);
    const lastManagementAreaIdRef = React.useRef(0);
    const [anchorEl, setAnchorEl] = useState<PopperProps["anchorEl"]>(null);


    async function getManagementAreaProgramProgressDetails() {
        try {
            var response = await fetchWithAuthorisationHeader(settings.CMPLY_API_URL + 'managementArea/progressDetail/' + props.config.managementAreaId);
            let managementAreaHoverDetails = response.data as IManagementAreaProgramProgress;
            managementAreaHoverDetails.managementAreaPrimaryProgramStatusOutlineColor =
                managementAreaHoverDetails.managementAreaPrimaryProgramStatusOutlineColor == null ? "#46BCFF" : managementAreaHoverDetails.managementAreaPrimaryProgramStatusOutlineColor;
            managementAreaHoverDetails.managementAreaPrimaryProgramStatusFillColor =
                managementAreaHoverDetails.managementAreaPrimaryProgramStatusFillColor == null ? "#2b2b2b" : managementAreaHoverDetails.managementAreaPrimaryProgramStatusFillColor;

            //Shift the primary program as the first program
            const foundIdx = managementAreaHoverDetails.programManagementAreaDetails.findIndex(pma => pma.programId == managementAreaHoverDetails.primaryProgramId);
            managementAreaHoverDetails.programManagementAreaDetails.unshift(managementAreaHoverDetails.programManagementAreaDetails[foundIdx]);
            managementAreaHoverDetails.programManagementAreaDetails.splice(foundIdx+1, 1);

            //Find program asset progress percentage
            managementAreaHoverDetails.programManagementAreaDetails.map(pma => {
                pma.progressPercentage = managementAreaHoverDetails.totalAssetsCount != 0
                    ? Math.round((pma.completedProgramAssetsCount / managementAreaHoverDetails.totalAssetsCount) * 100)
                    : 0;
            });

            setManagementAreaHoverDetails(managementAreaHoverDetails);
        } catch (e) {
            console.log(e);
        }
    }

    function onClose()
    {
        setAnchorEl(null);
    }

    useEffect(() => {
        if (props.closeHoverBox) {
            onClose();
        }
    }, [props.closeHoverBox]);

    useEffect(() => {
        if (props.config.managementAreaId !== lastManagementAreaIdRef.current) {
            lastManagementAreaIdRef.current = (props.config.managementAreaId);

            if (props.config.managementAreaId > 0) {

                getManagementAreaProgramProgressDetails();

                const selection: ClientRect = {
                    top: props.config.screenY + 90,
                    right: props.config.screenX,
                    width: 1,
                    height: 1,
                    bottom: props.config.screenY,
                    left: props.config.screenX + 90,
                    x: 0,
                    y: 0,
                    toJSON: function () {
                        throw new Error('Function not implemented.');
                    }
                };
                const getBoundingClientRect = () => selection;

                setAnchorEl({ getBoundingClientRect: getBoundingClientRect });
            }
            else {
                setAnchorEl(null);
            }
        }
    }, [props.config]);

    let listItems: JSX.Element[] = [];
    if (managementAreaHoverDetails != null) 
    {
        managementAreaHoverDetails.programManagementAreaDetails.map((pma: any) => (
            listItems.push(
                <Grid container style={{marginTop: 8 }} direction="row">
                    <Grid item xs={6} alignSelf="center" justifySelf="center">
                        <Typography noWrap variant="h6" style={{ fontSize: 12, color: '#ffffff' }}>
                            {pma.programName}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} alignSelf="center" justifySelf="center">
                        <Typography noWrap variant="h6" style={{fontSize:12, color:'#ffffff'}} >
                            ({pma.programTypeName})
                        </Typography>
                    </Grid>
                    <Grid item xs={2} alignSelf="center" justifySelf="center">
                        <Box className={styles.progressRingContainer}>
                            <CircularProgress size={40} value={pma.progressPercentage} variant="determinate" />
                            <Box className={styles.progressRingOverlay}>
                                <Typography variant="caption" sx={{ lineHeight: "41px" }}>{pma.progressPercentage}%</Typography>
                            </Box>
                            <Box className={styles.progressRingBackground}></Box>
                        </Box>

                    </Grid>
                </Grid>)
        ));
    }

    return (
        <div>
           <Popper
                placement="right-start"
                disablePortal={false}
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && managementAreaHoverDetails != null}
           >

           {managementAreaHoverDetails != null && (
                    <div className={styles.container} style={{ display: props.config.managementAreaId > 0 ? 'block' : 'none' }}>
                    <Grid container className={styles.content} direction="column">
                        <Grid item>
                                <Grid container className={styles.header} style={{width:260}} direction="row">
                            <Grid item xs={11}>
                                        <Typography noWrap variant="h6" style={{ fontSize: 12, fontWeight: 600, color:'#ffffff'}}>
                                    {managementAreaHoverDetails.managementAreaName}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    disableRipple={true}
                                    disableFocusRipple={true}
                                    onClick={() => onClose()}
                                >
                                    <ClearIcon className={styles.clear} />
                                </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <span
                                style={{
                                    position: 'absolute',
                                    transform: 'translateY(-50%)',
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    outlineColor: managementAreaHoverDetails.managementAreaPrimaryProgramStatusOutlineColor,
                                    backgroundColor: managementAreaHoverDetails.managementAreaPrimaryProgramStatusFillColor,
                                    marginTop: '9px'
                                }}
                            />
                            <Typography noWrap variant="h6" style={{fontSize:12, color:'#ffffff', marginLeft:18}}>
                                {managementAreaHoverDetails.managementAreaPrimaryProgramStatus}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container display="flex" justifyContent="flex-end" direction="column" style={{ width: 290}}>
                                {listItems}
                            </Grid>
                         </Grid>
                    </Grid>
              </div>)
           }
            </Popper>
        </div>
    );
}
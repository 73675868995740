import React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';

import aims3dIcon from '../../images/aims3d-icon.png';
import vaaIcon from '../../images/vaa-icon.png';

import { getAuthorizationData, fetchWithAuthorisationHeader, removeAuthorizationData } from '../../services/AuthenticationService';

import styles from './ProductMenu.module.css';

import settings from '../../config';

interface IProductMenu {
  anchorEl: any;
  open: boolean;
  handleClose: any;
  canAims: boolean;
  canVaa: boolean;
}

const productClickHandler = async (productName: string) => {
  let authData = await getAuthorizationData();
  await fetchWithAuthorisationHeader(settings.AIMS3D_API_URL + 'account/userProductSwitch/' + productName);

  let redirectUrl = '';
  switch (productName) {
    case 'AIMS3D': {
      redirectUrl = settings.AIMS3D_URL + '?rt=' + authData.refreshToken;
      break;
    }
    case 'AIMSVAA': {
      redirectUrl = settings.VAA_URL + '?rt=' + authData.refreshToken;
      break;
    }
    default:
      break;
  }

  if (redirectUrl !== '') {
    window.location.href = redirectUrl;
  }
};

const ProductMenu = (props: IProductMenu) => {
  return (
    <Menu
      anchorEl={props.anchorEl}
      id='account-menu'
      open={props.open}
      onClose={props.handleClose}
      onClick={props.handleClose}
      PaperProps={{
        elevation: 4,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 0.4,
          minWidth: 220,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1.25,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
      <MenuItem>
        <span className={styles.productName} style={{ color: '#EEEEEE' }}>
          AIMS Platform
        </span>
      </MenuItem>
      {props.canAims && (
        <MenuItem
          className={styles.product}
          onClick={() => {
            productClickHandler('AIMS3D');
          }}>
          <Avatar variant='square' className={styles.productIcon} src={aims3dIcon} />
          <span className={styles.productName} style={{ color: '#A6A9DD' }}>
            AIMS 3D
          </span>
        </MenuItem>
      )}

      {props.canVaa && (
        <MenuItem
          className={styles.product}
          onClick={() => {
            productClickHandler('AIMSVAA');
          }}>
          <Avatar variant='square' className={styles.productIcon} src={vaaIcon} />
          <span className={styles.productName} style={{ color: '#8D75CC' }}>
            AIMS VAA
          </span>
        </MenuItem>
      )}
      <MenuItem
          className={styles.product}
          onClick={() => {
            window.location.href = settings.AIMS3D_URL + 'admin';
          }}>
          <SettingsIcon style={{ color: '#AFAFAF' }} />
          <span className={styles.productName} style={{ color: '#AFAFAF', marginLeft:13 }}>
            ADMIN DASHBOARD
          </span>
        </MenuItem>
      <Divider />
      <MenuItem
        className={styles.product}
        onClick={() => {
          removeAuthorizationData();
          window.location.href = settings.AIMS3D_URL + '#/signOut';
        }}>
        <LogoutIcon style={{ color: '#EEEEEE' }}/>
        <span className={styles.productName} style={{ color: '#EEEEEE', marginLeft:13 }}>
          Sign Out
        </span>
      </MenuItem>
    </Menu>
  );
};

export default ProductMenu;

import React, { useState, useEffect, useRef } from 'react';
import { Avatar, Badge, createMuiTheme, createTheme, Grid, ThemeProvider, Tooltip, Typography } from '@mui/material';
import NatureIcon from '@mui/icons-material/Nature';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import styles from './Programs.module.css';
import { IProgram } from '../../../../models/IProgram';


interface IProgramsProps {
    programs: IProgram[];
}

export function Programs(props: IProgramsProps) {

    //program type enum
    const PROGRAM_TYPES = {
        cyclic: <Avatar className={`${styles.avatar} ${styles.cyclic}`}> <FlipCameraAndroidIcon /></Avatar>,
        other: <Avatar className={`${styles.avatar} ${styles.other}`}><NatureIcon /></Avatar>,
    }

    function groupByKey(keyArray: string[], objectArray: any[], property: any, fallbackKey: any) {
        return objectArray.reduce((acc, obj) => {
            const objKey = (obj[property] as string).toLowerCase();
            const key = keyArray.includes(objKey) ? objKey : fallbackKey;
            const curGroup = acc[key] ?? [];

            return { ...acc, [key]: [...curGroup, obj] };
        }, {});
    }
    let iconsJsx: JSX.Element[] = [];

    let alltypes: string[] = [];
    const fallbackType = "other";
    const grouped = groupByKey(Object.keys(PROGRAM_TYPES), props.programs, "programType", fallbackType);

    let sortedTypes: string[] = Object.keys(grouped);
    sortedTypes.sort();
    if (sortedTypes.indexOf(fallbackType) !== -1) {
        sortedTypes.push(sortedTypes.splice(sortedTypes.indexOf(fallbackType), 1)[0]);  // fallback type always last
    }

    for (var programType of sortedTypes) {

        let tempProgramListJsx: JSX.Element[] = [];
        for (var item of grouped[programType]) {
            tempProgramListJsx.push(<span><br />{item.programName}</span>);
        }
        let badgeCount: number = grouped[programType].length;
        if (!alltypes.includes(programType)) {
            iconsJsx.push(
                <Grid item key={programType}>
                    {badgeCount > 1 && (
                        <Badge
                            className={styles.badgeColor}
                            color="error"
                            sx={{
                                position: "absolute",
                                left: 14,
                                top: 2,
                                paddingTop: 0,
                                paddingBottom: 0,
                            }}
                            overlap="circular"
                            badgeContent={badgeCount < 10 ? badgeCount : "9+"}
                        />
                    )}
                    <Tooltip arrow placement="top-end"
                        title={
                            <React.Fragment>
                                <span className={styles.tooltipTitle}>{programType} Programs:</span>
                                {tempProgramListJsx}
                            </React.Fragment>}>
                        {PROGRAM_TYPES[programType as keyof typeof PROGRAM_TYPES]}
                    </Tooltip>
                </Grid>
            );
            alltypes.push(programType);
        }
    }

    return (
        <Grid container direction="row" >
            {iconsJsx}
        </Grid>
    );
}
